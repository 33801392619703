<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="课程添加课程内容" :visible.sync="AddCourseContentToCourseDialogVisible" 
    :close-on-click-modal="false" v-alterELDialogMarginTop="{marginTop:'7vh'}" width="65%" @close="CloseDialog">
         <div class="coursecontentdialog_div">
            <el-row style="margin-bottom: 12px" >
                <el-descriptions :column="2" title="课程信息" v-loading="Loading">
                    <el-descriptions-item label="名称">{{courseinfo.name}}</el-descriptions-item>
                    <el-descriptions-item label="描述">{{courseinfo.description}}</el-descriptions-item>
                </el-descriptions>
            </el-row>
            <el-card>
                <el-row :gutter="30">
                    <el-col :span="8">
                        <el-input placeholder="请输入课程名称" v-model="QueryInfo.Name" clearable @clear="initQueryCourseContentList"> 
                            <el-button slot="append" icon="el-icon-search" @click="initQueryCourseContentList"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
                <el-table :data="CourseContentList" @row-click="CourseContentClick" stripe v-loading="Loading"  height="40vh">
                    <el-table-column label="#" type="index"></el-table-column>
                    <el-table-column label="名称" prop="name"></el-table-column>
                    <el-table-column label="创建者" prop="creatorName"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime" sortable></el-table-column>
                    <el-table-column label="操作课程内容" prop="option" >
                        <template slot-scope="CourseContentInfo"> 
                            <!-- {{ scope.row}} -->
                            <!-- 因为switch在@row-click 触发 行点击事件，导致点击switch时候会触发行点击事件，在外面一层加入 @click.stop 可以阻止冒泡 -->
                            <div @click.stop>
                                <el-switch :value="CourseContentInfo.row.addTag" active-color="#13ce66" inactive-color="#ff4949"
                                :active-value="0" :inactive-value="1" @change="ModifyCourseContentLink(CourseContentInfo.row)" >
                                </el-switch>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="HandleSizeChange"
                    @current-change="HandleCurrentChange"
                    :current-page="QueryInfo.Start"
                    :page-sizes="spreadCount"
                    :page-size="QueryInfo.Count"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="CourseContentTotal">
                </el-pagination>
            </el-card>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog">关闭</el-button>
        </span>
        <ModifyCourseContentDialog :visible.sync="ModifyCourseContentDialogVisible"  :coursecontentinfo="ModifyCourseContentInfo"
            v-on:ChangeModifyCourseContentInfo="ParentChangeModifyCourseContentInfo($event)">
        </ModifyCourseContentDialog>
    </el-dialog>
</template>

<script>
import { GetCourseContentsQueryNotInCourseID, GetCourseContentByID, PutCourseContentLinkInfo } from '@/assets/js/api/courseapi' 
import ModifyCourseContentDialog from '../CourseClassFicationManager/modifycoursecontentdialog';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    components:{
        ModifyCourseContentDialog
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        courseinfo:Object
    },
    computed:{
        AddCourseContentToCourseDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        },
    },
    data(){
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                Name:'',
                Start:0,
                Count:SpreadCount[0],
                CourseID: this.courseinfo.id
            },
            CourseContentList:[],
            CourseContentTotal:0,
            ModifyCourseContentDialogVisible:false,
            ModifyCourseContentInfo:{},
            Loading:false
        }
    },
    watch:{
        AddCourseContentToCourseDialogVisible:function (newVisible) {
            if(newVisible){
                this.QueryCourseContentList()
            }
        }
    },
    created(){
        
    },
    methods:{
        async CourseContentClick(row){
            var ret = await GetCourseContentByID(row.id);
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.ModifyCourseContentInfo = ret.data
                this.ModifyCourseContentDialogVisible = true
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryCourseContentList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryCourseContentList()
        },
        initQueryCourseContentList(){
            this.QueryInfo.Start = 1
            this.QueryInfo.Count = this.spreadCount[0]
            this.QueryCourseContentList()
        },
        async QueryCourseContentList(){
            this.Loading = true
            this.QueryInfo.CourseID = this.courseinfo.id
            var ret = await GetCourseContentsQueryNotInCourseID(this.QueryInfo)
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseContentList = ret.data.coursecontents
                this.CourseContentTotal = ret.data.total
                this.Loading = false
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        CloseDialog(){
            this.Loading = false
            this.AddCourseContentToCourseDialogVisible = false
            this.QueryInfo.Name = ''
            this.QueryInfo.Start = 0
            this.QueryInfo.Count = this.spreadCount[0]
            this.QueryInfo.CourseID = 0
        },
        async ModifyCourseContentLink(info){
            var obj = {};
            obj.AddTag = info.addTag
            obj.CourseID = this.courseinfo.id
            obj.ID = info.id
            this.Loading = true
            var ret = await PutCourseContentLinkInfo(obj)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改成功');
                if(info.addTag == 0)
                {
                    info.addTag = 1
                    this.$emit("ChangeDeleteCourseContentInfo", info)
                }
                else if(info.addTag == 1)
                {
                    info.addTag = 0
                    this.$emit("ChangeAddCourseContentInfo", info)
                }
            }
            else
            {
                this.$message.error(ret.message);
                return;
            }
        },
        ParentChangeModifyCourseContentInfo(coursecontent){
            for (var i = 0; i < this.CourseContentList.length; i++) {
                if (this.CourseContentList[i]["id"] == coursecontent.id) {
                    this.CourseContentList[i]["name"] = coursecontent.name
                    this.CourseContentList[i]["creatorName"] = coursecontent.creatorName
                    break
                }
            }
        }
    }
}
</script>

<style scoped lang="less">
    .dialog_div{
        text-align: center;
    }
    .coursecontentdialog_div{
        text-align: center;
    }
</style>