<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入课程名称" v-model="QueryInfo.Name" clearable @clear="initQueryCourseList"> 
                        <el-button slot="append" icon="el-icon-search" @click="initQueryCourseList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="AddCourseDialog">新建课程</el-button> 
                </el-col>
            </el-row>
            <el-table :data="CourseList" @row-click="CourseClick" v-loading="Loading" height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="默认课时" prop="courseValue"></el-table-column>
                <el-table-column label="价格（￥）" prop="price"></el-table-column>
                <el-table-column label="描述" prop="description"></el-table-column>
                <el-table-column label="所属课包" prop="courseClassFicationName"></el-table-column>
                <el-table-column label="启用" prop="option" width="100">
                    <template slot-scope="scope"> 
                        <!-- {{ scope.row}} -->
                        <!-- 因为switch在@row-click 触发 行点击事件，导致点击switch时候会触发行点击事件，在外面一层加入 @click.stop 可以阻止冒泡 -->
                        <div @click.stop>
                            <el-switch :value="scope.row.isDelete" active-color="#13ce66" inactive-color="#ff4949"
                               :active-value="0" :inactive-value="1" @change="ChangeCourseOption(scope.row)" >
                            </el-switch>
                            
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" prop="option">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="课程内容" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-document-add" @click.stop="ShowCourseContent(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="CourseTotal">
            </el-pagination>
            <el-dialog title="新建课程" :visible.sync="AddCourseVisible" width="30%" @close="AddCourseDialogClose" :close-on-click-modal="false">
                <el-form ref="AddCourseFormRef" :model="AddCourseForm" :rules="AddCourseFormRules" 
                label-width="80px" v-loading="AdLoading">
                    <el-form-item label="名称" prop="Name">
                        <el-input placeholder="请输入课程名称" v-model="AddCourseForm.Name"></el-input>
                    </el-form-item>
                    <el-form-item label="价格" prop="Price">
                        <el-input placeholder="请输入课程价格" v-model="AddCourseForm.Price" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" @blur="PriceChange"></el-input>
                    </el-form-item>
                    <el-form-item class="form_div" label="默认课时" prop="CourseValue">
                        <el-input-number v-model="AddCourseForm.CourseValue" :precision="1" :step="0.5" :min="0" :max="100"></el-input-number>
                    </el-form-item>
                    <el-form-item label="描述" prop="Description">
                        <el-input placeholder="请输入课程描述" v-model="AddCourseForm.Description"></el-input>
                    </el-form-item>
                    <el-form-item label="课包分类" prop="ClassFicationID">
                        <el-select style="display:table" width="30" v-model="AddCourseForm.ClassFicationID" placeholder="请选择课包" >
                            <el-option
                            v-for="item in CourseClassificationList"
                            :key="item.id"
                            :label="item.courseClassFicationName"
                            :value="item.id">
                                <span style="float: left">{{ item.courseClassFicationName }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddCourseDialogClose">取消</el-button>
                    <el-button type="primary" @click="AddCourse" :disabled="AdLoading">确定</el-button>
                </span>
            </el-dialog>

            <el-dialog title="编辑课程" :visible.sync="ModifyCourseVisible" width="30%" @close="ModifyCourseDialogClose" :close-on-click-modal="false">
                <el-form ref="ModifyCourseFormRef" :model="ModifyCourseForm" :rules="ModifyCourseFormRules" 
                label-width="80px" v-loading="EdLoading">
                    <el-form-item label="名称" prop="Name">
                        <el-input placeholder="请输入课程名称" v-model="ModifyCourseForm.Name"></el-input>
                    </el-form-item>
                    <el-form-item label="价格" prop="Price">
                        <el-input placeholder="请输入课程价格" v-model="ModifyCourseForm.Price" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" @blur="PriceChange"></el-input>
                    </el-form-item>
                    <el-form-item class="form_div" label="默认课时" prop="CourseValue">
                        <el-input-number v-model="ModifyCourseForm.CourseValue" :precision="1" :step="0.5" :min="0" :max="100"></el-input-number>
                    </el-form-item>
                    <el-form-item label="描述" prop="Description">
                        <el-input placeholder="请输入课程描述" v-model="ModifyCourseForm.Description"></el-input>
                    </el-form-item>
                    <el-form-item label="课包分类" prop="ClassFicationID">
                        <el-select style="display:table" width="30" v-model="ModifyCourseForm.ClassFicationID" placeholder="请选择课包" >
                            <el-option
                            v-for="item in CourseClassificationList"
                            :key="item.id"
                            :label="item.courseClassFicationName"
                            :value="item.id">
                                <span style="float: left">{{ item.courseClassFicationName }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="ModifyCourseDialogClose">取消</el-button>
                    <el-button type="primary" @click="ModifyCourse" :disabled="EdLoading">确定</el-button>
                </span>
            </el-dialog>
        </el-card>
        <CourseContentListDialog :visible.sync="CourseContentListDialogVisible" :courseinfo="CourseInfo">
        </CourseContentListDialog>
    </div>
</template>

<script>
import { PostCourseInfo, GetQueryCourseList, PutCourseInfo } from '@/assets/js/api/courseapi' 
import { GetQueryCourseClassFicationList } from '@/assets/js/api/courseclassficationapi' 
import CourseContentListDialog from './coursecontentlistdialog';
import { SpreadCount } from '@/assets/js/systemvariable';
export default {
    components:{
        CourseContentListDialog,
    },
    data(){
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                Name:'',
                Start:0,
                Count:SpreadCount[0]
            },
            CourseList:[],
            CourseTotal:0,
            AddCourseVisible:false,
            AddCourseForm:{
                Name:'',
                Price:'',
                Description:'',
                ClassFicationID:'',
                CourseValue:1
            },
            AddCourseFormRules:{
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                ClassFicationID: [
                    { required: true, message: '请选择课包', trigger: 'change' }
                ]
            },
            ModifyCourseVisible:false,
            ModifyCourseForm:{
                ID:'',
                Name:'',
                Price:'',
                Description:'',
                ClassFicationID:'',
                CourseValue:1,
            },
            ModifyCourseFormRules:{
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                ClassFicationID: [
                    { required: true, message: '请选择课包', trigger: 'change' }
                ]
            },
            CourseClassificationList:[],
            Loading:false,
            CourseContentListDialogVisible:false,
            CourseInfo:{},
            AdLoading:false,
            EdLoading:false,
        }
    },
    created(){
        this.QueryCourseList()
    },
    methods:{
        ShowCourseContent(info){
            this.CourseInfo = info
            this.CourseContentListDialogVisible = true
        },
        async QueryCourseClassFicationList(){
            var query = {Name:'',
                Start:0,
                Count:100}
            this.AdLoading = true
            this.EdLoading = true
            var ret = await GetQueryCourseClassFicationList(query)
            this.EdLoading = false
            this.AdLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseClassificationList = ret.data.courseclassfications
                if(this.CourseClassificationList.length == 0)
                {
                    this.$message.error('没有可用课包，请先新建课包！');
                    this.$refs.AddCourseFormRef.resetFields();
                    this.AddCourseVisible = false
                    this.$refs.ModifyCourseFormRef.resetFields();
                    this.ModifyCourseVisible = false
                }
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        initQueryCourseList(){
            this.QueryInfo.Start = 1
            this.QueryInfo.Count = this.spreadCount[0]
            this.QueryCourseList()
        },
        async QueryCourseList(){
            this.Loading = true
            var ret = await GetQueryCourseList(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseList = ret.data.courses
                this.CourseTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        PriceChange(e){
            this.AddCourseForm.Price = e.target.value
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryCourseList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryCourseList()
        },
        AddCourseDialogClose(){
            this.$refs.AddCourseFormRef.resetFields();
            this.AddCourseVisible = false
        },
        AddCourseDialog(){
            this.QueryCourseClassFicationList()
            this.AddCourseVisible = true
        },
        async AddCourse(){
            this.$refs.AddCourseFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            //使用*1可以使string转为double
            this.AddCourseForm.Price = this.AddCourseForm.Price * 1
            this.AdLoading = true
            var ret = await PostCourseInfo(this.AddCourseForm)
            this.AdLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('新建课程成功');
                this.$refs.AddCourseFormRef.resetFields();
                this.AddCourseVisible = false
                this.QueryCourseList()
            }
            else
            {
                this.$message.error(ret.message);
            } 
        },
        async ChangeCourseOption(course){
            const confirmResult = await this.$confirm('此操作将改变课程状态，可能使课程无法使用。 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消删除')
            }
            if(course.isDelete == 0)
            {
                course.isDelete = 1
            }
            else
            {
                course.isDelete = 0
            }
            this.Loading = true
            var ret = await PutCourseInfo(course)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改成功');
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        CourseClick(course){
            this.ModifyCourseForm.ID = course.id
            this.ModifyCourseForm.Name = course.name
            this.ModifyCourseForm.Price = course.price
            this.ModifyCourseForm.Description = course.description
            this.ModifyCourseForm.ClassFicationID = course.classFicationID
            this.ModifyCourseForm.CourseValue = course.courseValue
            this.ModifyCourseVisible  = true
            this.QueryCourseClassFicationList()
        },
        ModifyCourseDialogClose(){
            this.$refs.ModifyCourseFormRef.resetFields();
            this.ModifyCourseVisible = false
        },
        async ModifyCourse(){
            this.$refs.ModifyCourseFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            let it = this.CourseList.find(item => item.id == this.ModifyCourseForm.ID);
            if(it.name == this.ModifyCourseForm.Name && 
            it.price == this.ModifyCourseForm.Price && 
            it.description == this.ModifyCourseForm.Description && 
            it.classFicationID == this.ModifyCourseForm.ClassFicationID &&
            it.courseValue == this.ModifyCourseForm.CourseValue){
                return this.$message.warning('数据没有修改，不需要提交');
            }
            //使用*1可以使string转为double
            this.ModifyCourseForm.Price = this.ModifyCourseForm.Price * 1
            var ret = await PutCourseInfo(this.ModifyCourseForm)
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改成功');
                for (var i = 0; i < this.CourseList.length; i++) {
                    if (this.CourseList[i]["id"] == this.ModifyCourseForm.ID) {
                        this.CourseList[i]["name"] = this.ModifyCourseForm.Name
                        this.CourseList[i]["price"] = this.ModifyCourseForm.Price
                        this.CourseList[i]["description"] = this.ModifyCourseForm.Description
                        this.CourseList[i]["courseValue"] = this.ModifyCourseForm.CourseValue
                    }
                }
                this.$refs.ModifyCourseFormRef.resetFields();
                this.ModifyCourseVisible = false
                this.QueryCourseList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    }
}
</script>

<style scoped lang="less">
.form_div{
    text-align: left;
}
</style>