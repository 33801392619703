<template>
    <el-dialog :append-to-body="true" :visible.sync="CourseContentListDialogVisible" :close-on-click-modal="false" 
    v-alterELDialogMarginTop="{marginTop:'7vh'}" width="70%">
        <div class="coursecontentdialog_div">
            <el-card>
                <el-row :gutter="30">
                    <el-col :span="8">
                        <el-input placeholder="请输入课程名称" v-model="QueryInfo.Name" clearable @clear="initQueryCourseContentList"> 
                            <el-button slot="append" icon="el-icon-search" @click="initQueryCourseContentList"></el-button>
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-button type="primary" @click="AddCourseContentToCourseDialogVisible= true" :disabled="Loading">课程添加课程内容</el-button>
                    </el-col>
                </el-row>
                <el-table :data="CourseContentList" @row-click="CourseContentClick" stripe v-loading="Loading" height="calc(100vh - 50vh)">
                    <el-table-column label="#" type="index"></el-table-column>
                    <el-table-column label="名称" prop="name"></el-table-column>
                    <el-table-column label="创建者" prop="creatorName"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime" sortable></el-table-column>
                    <el-table-column label="删除课程内容" prop="option" >
                        <template slot-scope="CourseContentInfo"> 
                            <!-- {{ scope.row}} -->
                            <!-- 因为switch在@row-click 触发 行点击事件，导致点击switch时候会触发行点击事件，在外面一层加入 @click.stop 可以阻止冒泡 -->
                            <!-- <div @click.stop>
                                <el-switch :value="scope.row.addTag" active-color="#13ce66" inactive-color="#ff4949"
                                :active-value="0" :inactive-value="1" @change="ModifyCourseContentLink(scope.row)" >
                                </el-switch>
                            </div> -->
                            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                                <el-button type="danger" icon="el-icon-delete" @click.stop="ModifyCourseContentLink(CourseContentInfo.row)"></el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="HandleSizeChange"
                    @current-change="HandleCurrentChange"
                    :current-page="QueryInfo.Start"
                    :page-sizes="spreadCount"
                    :page-size="QueryInfo.Count"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="CourseContentTotal">
                </el-pagination>
            </el-card>
            
        </div>
        <span slot="footer" >
            <el-button @click="CourseContentListDialogVisible = false">关闭</el-button>
        </span>
        <ModifyCourseContentDialog :visible.sync="ModifyCourseContentDialogVisible"  :coursecontentinfo="ModifyCourseContentInfo"
        v-on:ChangeModifyCourseContentInfo="ParentChangeModifyCourseContentInfo($event)">
        </ModifyCourseContentDialog>

         <AddCourseContentToCourseDialog :visible.sync="AddCourseContentToCourseDialogVisible" :courseinfo="this.courseinfo"
          v-on:ChangeAddCourseContentInfo="ParentChangeAddCourseContentInfo($event)"
          v-on:ChangeDeleteCourseContentInfo="ParentChangeDeleteCourseContentInfo($event)">
        </AddCourseContentToCourseDialog>

    </el-dialog>
</template>

<script>
import ModifyCourseContentDialog from '../CourseClassFicationManager/modifycoursecontentdialog';
import AddCourseContentToCourseDialog from '../CourseClassFicationManager/addcoursecontenttocoursedialog';
import { GetCourseContentsQueryByCourseID, GetCourseContentByID, PutCourseContentLinkInfo } from '@/assets/js/api/courseapi' ;
import { SpreadCount } from '@/assets/js/systemvariable';
export default {
    components:{
        ModifyCourseContentDialog,
        AddCourseContentToCourseDialog,
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        courseinfo:Object
    },
    computed:{
        CourseContentListDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        },
    },
    data(){
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                Name:'',
                Start:0,
                Count:SpreadCount[0],
                CourseID:0
            },
            CourseContentList:[],
            CourseContentTotal:0,
            ModifyCourseContentDialogVisible:false,
            ModifyCourseContentInfo:{},
            AddCourseContentToCourseDialogVisible:false,
            Loading:false
        }
    },
    watch:{
        CourseContentListDialogVisible:function (newVisible) {
            if(newVisible){
                this.QueryCourseContentList()
            }
        }
    },
    created(){
        
    },
    methods:{
        async CourseContentClick(row){
            var ret = await GetCourseContentByID(row.id);
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.ModifyCourseContentInfo = ret.data
                this.ModifyCourseContentDialogVisible = true
            }
            else
            {
                this.$message.error(ret.message);
            } 
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryCourseContentList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryCourseContentList()
        },
        initQueryCourseContentList(){
            this.QueryInfo.Start = 1
            this.QueryInfo.Count = this.spreadCount[0]
            this.QueryCourseContentList()
        },
        async QueryCourseContentList(){
            this.Loading = true
            this.QueryInfo.CourseID = this.courseinfo.id
            var ret = await GetCourseContentsQueryByCourseID(this.QueryInfo)
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseContentList = ret.data.coursecontents
                this.CourseContentTotal = ret.data.total
                this.Loading = false
            }
            else
            {
                this.$message.error(ret.message);
            } 
        },
        async ModifyCourseContentLink(info){
            const confirmResult = await this.$confirm('删除课程内容，将导致该课程内容无法签到?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消删除')
            }
            var obj = {};
            //AddTag==0删除课程
            obj.AddTag = 0
            obj.CourseID = this.courseinfo.id
            obj.ID = info.id
            this.Loading = true
            var ret = await PutCourseContentLinkInfo(obj)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除成功');
                // var index = 0
                // for(var i = 0; i < this.CourseContentList.length; i++){
                //     if(this.CourseContentList[i].id == info.id){
                //     index = i
                //     }
                // }
                // this.CourseContentList.splice(index,1)
                // this.CourseContentTotal --
                this.QueryCourseContentList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        ParentChangeModifyCourseContentInfo(coursecontent){
            for (var i = 0; i < this.CourseContentList.length; i++) {
                if (this.CourseContentList[i]["id"] == coursecontent.id) {
                    this.CourseContentList[i]["name"] = coursecontent.name
                    this.CourseContentList[i]["creatorName"] = coursecontent.creatorName
                    break
                }
            }
        },
        ParentChangeAddCourseContentInfo(coursecontent){
            this.CourseContentList.push(coursecontent)
            this.CourseContentTotal ++
        },
        ParentChangeDeleteCourseContentInfo(coursecontent){
            var index = 0
            for(var i = 0; i < this.CourseContentList.length; i++){
                if(this.CourseContentList[i].id == coursecontent.id){
                index = i
                }
            }
            this.CourseContentList.splice(index,1)
            this.CourseContentTotal --
        }
    }
}
</script>

<style scoped lang="less">
.coursecontentdialog_div{
    text-align: center;
}
</style>